$balanceLeftColor: #fa8c16;
$balanceColor: #4078f2;
$amountColor: #77af2d;
$accountNumberColor: #666666;

.balance {
  font-weight: 400px;
  color: $balanceColor;
  margin: 0px;
  line-height: 1px;
  float: left;
}

.balance-rp {
  font-weight: 400px;
  color: $balanceColor;
  font-size: 20px;
  margin: 0px;
  line-height: 1px;
  vertical-align: 5px;
}

.balance-number {
  font-weight: 400px;
  color: $balanceColor;
  font-size: 30px;
  margin: 0px;
  line-height: 1px;
}

.balance-left {
  font-weight: 400px;
  color: $balanceLeftColor;
  margin: 0px;
  line-height: 1px;
  float: left;
}

.balance-left-rp {
  font-weight: 400px;
  color: $balanceLeftColor;
  font-size: 20px;
  margin: 0px;
  line-height: 1px;
  vertical-align: 5px;
}

.balance-left-number {
  font-weight: 400px;
  color: $balanceLeftColor;
  font-size: 30px;
  margin: 0px;
  line-height: 1px;
}

.amount {
  font-weight: 400px;
  color: $amountColor;
  margin: 0px;
  line-height: 1px;
  float: left;
}

.amount-rp {
  font-weight: 400px;
  color: $amountColor;
  font-size: 20px;
  margin: 0px;
  line-height: 1px;
  vertical-align: 5px;
}

.amount-number {
  font-weight: 400px;
  color: $amountColor;
  font-size: 30px;
  margin: 0px;
  line-height: 1px;
}

.account-number-text-block {
  color: $accountNumberColor;
  font-weight: 400px;
  font-size: 1.2rem;
  margin: 0px;
  line-height: 1px;
  padding-bottom: 30px;
  display: block;
}

.account-number-text {
  color: $accountNumberColor;
  font-weight: 400px;
  font-size: 1.2rem;
  margin: 0px;
  line-height: 1px;
  padding-bottom: 30px;
}

.deeplink {
  font-weight: 400px;
  font-size: 1.2rem;
}

.account-number {
  color: $accountNumberColor;
  font-weight: 400px;
  font-size: 2rem;
  margin: 0px;
  line-height: 1px;
  display: block;
  padding-bottom: 6px;
}

.transaction-detail-text {
  font-weight: 400px;
  color: $accountNumberColor;
  margin: 0px;
  line-height: 1px;
  display: block;
  padding-bottom: 10px;
}

.transaction-detail-description {
  font-weight: 400px;
  font-size: 16px;
  margin: 0px;
}
